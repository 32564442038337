@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background: #f3f1f6;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Outfit", system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-gutter: thin;
  background-color: #f3f1f6;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: grab;
}

::-webkit-scrollbar-track {
  background-color: "transparent";
  border-radius: 10px;
}

/* hovered track */
::-webkit-scrollbar-thumb:hover {
  background-color: "transparent";
}

::-webkit-scrollbar-thumb {
  border: solid rgba(236, 28, 35, 0);
  background-clip: padding-box;
  background-color: rgba(236, 28, 35, 0.3);
  border-radius: 10px;
  box-shadow: inset -1px -1px 0px rgba(236, 28, 35, 0.5),
    inset 1px 1px 0px rgba(236, 28, 35, 0.5);
  -webkit-border-radius: 10px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(236, 28, 35, 0.5),
    inset 1px 1px 0px rgba(236, 28, 35, 0.5);
}

::-webkit-scrollbar-button {
  display: block;
  width: 0;
  height: 5;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.scrollbarless::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbarless::-webkit-scrollbar {
  display: none;
}

.scrollbarless::-webkit-scrollbar-thumb {
  border: solid rgba(236, 28, 35, 0);
  background-clip: padding-box;
  background-color: rgba(236, 28, 35, 0.3);
  border-radius: 10px;
  box-shadow: inset -1px -1px 0px rgba(236, 28, 35, 0.5),
    inset 1px 1px 0px rgba(236, 28, 35, 0.5);
  -webkit-border-radius: 10px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(236, 28, 35, 0.5),
    inset 1px 1px 0px rgba(236, 28, 35, 0.5);
}

.scrollbarless::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.scrollbarless::-webkit-scrollbar-corner {
  background-color: transparent;
}

.glass {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.37);
}



/* taget only large screen with .stickScroll */
@media (min-width: 1024px) {
  .stickScroll {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
  }
}
